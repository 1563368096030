




import Vue from 'vue';
export default Vue.extend({
  mounted() {
    this.$store.dispatch('auth/signInAnonymously');
  },
});
